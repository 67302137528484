import { PageProps } from "gatsby"
import React from "react"
import SEO from "../../components/SEO"
import UCASDetails from "../../components/views/UCASDetails"

/**
 * The UCAS details page renders a page to confirm that the applicant
 * information received from UCAS is correct
 */

interface UCASDetailsPageLocation extends Omit<PageProps["location"], "state"> {
  state: {
    viewingFirstTime?: boolean | undefined
  }
}

export interface UCASDetailsPageProps extends Omit<PageProps, "location"> {
  location: UCASDetailsPageLocation
}

export default function UCASDetailsPage({
  location,
}: UCASDetailsPageProps): React.ReactNode {
  return (
    <>
      <SEO title="Check your details" />
      <UCASDetails viewingFirstTime={location.state?.viewingFirstTime} />
    </>
  )
}
