import React, { useState } from "react"
import Button from "../design-system/Button"
import T from "@mui/material/Typography"
import Grid, { GridProps } from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Skeleton from "../Skeleton"
import BlankAlert from "../BlankAlert"
import ErrorIcon from "@mui/icons-material/Error"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import { styled } from "@mui/material/styles"
import { navigate } from "gatsby"
import { useUser } from "../../providers/UserProvider"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { RadioQuestion as RadioQuestionType } from "../../data/question-schema"
import RadioQuestion from "../questions/RadioQuestion"
import { config, email } from "@ap-frontend/questions"
import Link from "../Link"
import ButtonWrapper from "../ButtonWrapper"
import { formatDate } from "@ap-frontend/utility"

const DetailsCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(6),
}))

const GridTitleItem: React.FC = ({ children }) => (
  <Grid component="dt" item xs={12} md={3} lg={2}>
    <T variant="body2" sx={{ mb: 0, fontWeight: "fontWeightBold" }}>
      {children}
    </T>
  </Grid>
)

const MissingText = styled("span")(({ theme }) => ({
  color: theme.palette.grey[600],
}))

const GridValueItem: React.FC = ({ children }) => (
  <Grid component="dd" item xs={12} md={9} lg={10}>
    <T variant="body2" sx={{ mb: [3, 2] }}>
      {children || <MissingText>Unknown</MissingText>}
    </T>
  </Grid>
)

const UCASDetails = ({
  viewingFirstTime,
}: {
  viewingFirstTime: boolean | undefined
}): React.ReactElement => {
  const { profile, activity, updateActivity } = UCASDetails.useUser()
  const isLoading = !profile

  const [blurred, setBlurred] = useState(false)
  const changed =
    activity?.confirmedUCASDetails !== undefined &&
    activity?.confirmedUCASDetails !== null

  const showErrors = !viewingFirstTime || changed || blurred

  const ucasRadioQuestion: RadioQuestionType = {
    id: "ucasRadioQuestion",
    type: "question",
    label: "Is this information correct?",
    component: {
      name: "Radio",
      properties: {
        options: [
          { value: true, label: "Yes" },
          {
            value: false,
            label:
              "No, I need to contact UCAS or the College to change these details",
          },
        ],
      },
    },
    answerJsonSchema: {
      type: "object",
      properties: {
        value: {
          enum: [true, false],
          errorMessage: {
            enum: "Select 'Yes' if the information is correct",
          },
        },
      },
      required: ["value"],
      errorMessage: {
        required: "Select 'Yes' if the information is correct",
      },
    },
  }

  const hasValidCollegeCode =
    profile?.college !== undefined && config.colleges[profile?.college]

  return (
    <>
      <T component="h1" variant="h1">
        Check your details
      </T>
      <T variant="body1">
        {"This is the information we've received from UCAS."}
      </T>
      <DetailsCard>
        <Grid
          {...({ component: "dl" } as GridProps)}
          container
          direction="row"
          spacing={0}
          sx={{ my: 0 }}
        >
          {isLoading ? (
            <>
              {Array.from(Array(12).keys()).map(o => (
                <GridValueItem key={o}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: 20, width: 200, mb: 3 }}
                  />
                </GridValueItem>
              ))}
              <GridValueItem>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 20, width: 200 }}
                />
              </GridValueItem>
              <GridValueItem>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 20, width: 200 }}
                />
              </GridValueItem>
            </>
          ) : (
            <>
              <GridTitleItem>Name</GridTitleItem>
              <GridValueItem>
                {profile.forenames} {profile.surname}
              </GridValueItem>
              <GridTitleItem>UCAS ID</GridTitleItem>
              <GridValueItem>{profile.ucasPersonalId}</GridValueItem>
              <GridTitleItem>Date of birth</GridTitleItem>
              <GridValueItem>{formatDate(profile.birthdate)}</GridValueItem>
              <GridTitleItem>Course</GridTitleItem>
              <GridValueItem>{profile.subjectDescription}</GridValueItem>
              <GridTitleItem>Course code</GridTitleItem>
              <GridValueItem>{profile.ucasCourse}</GridValueItem>
              <GridTitleItem>College</GridTitleItem>
              <GridValueItem>{profile.collegeDescription}</GridValueItem>
              <GridTitleItem>Entry year</GridTitleItem>
              <GridValueItem>{profile.entryYear}</GridValueItem>
            </>
          )}
        </Grid>
      </DetailsCard>

      <T variant="body1">
        {
          "If any information is incorrect, you'll need to change it with UCAS or with the College."
        }
      </T>
      <BlankAlert icon={<ErrorIcon fontSize="inherit" />}>
        <T variant="body1">
          You can start your application but these details must be correct
          before you submit it.
        </T>
      </BlankAlert>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="changeYourDetails-content"
          id="changeYourDetails-header"
        >
          How do I change these details?
        </AccordionSummary>
        <AccordionDetails>
          <T variant="body2">
            <strong>Name and date of birth</strong>
            <br />
            {"You'll need to  "}
            <Link href="https://www.ucas.com/forms/update-my-details-form">
              contact UCAS
            </Link>
            {
              ". UCAS will send us the updated information and we'll update your details here."
            }
          </T>
          <T variant="body2">
            <strong>Course details, College or entry year</strong>
            <br />
            {"You'll need to email "}
            <Link
              href={`mailto:${
                hasValidCollegeCode
                  ? config.colleges[profile?.college].email
                  : email
              }`}
            >
              {hasValidCollegeCode
                ? config.colleges[profile?.college].email
                : email}
            </Link>{" "}
            to change these details.
          </T>
        </AccordionDetails>
      </Accordion>
      <RadioQuestion.Input
        disabled={!!activity?.submissionTimestamp}
        question={ucasRadioQuestion}
        answer={
          activity?.confirmedUCASDetails !== undefined
            ? activity?.confirmedUCASDetails
            : null
        }
        showErrors={showErrors}
        setAnswer={a => updateActivity("confirmedUCASDetails", a as boolean)}
        setBlurred={setBlurred}
        data-test-id="ucas-details-radio"
      />
      <ButtonWrapper>
        <Button
          data-test-id="start-my-application-button"
          sx={{ mb: 4 }}
          onClick={() => navigate("/application")}
        >
          Continue to application
        </Button>
      </ButtonWrapper>
    </>
  )
}

// Setting these as static members allows us to mock during component testing
UCASDetails.useUser = useUser

export default UCASDetails
